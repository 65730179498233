import React, { Fragment, useRef, useState, useEffect } from 'react';

import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';

import { ReactComponent as Delete } from '../../assets/img/icons/common/Delete.svg';
import { ReactComponent as NoImage } from '../../assets/img/icons/common/NoImage.svg';
import ManagerPlayer from '../ManagerPlayer/ManagerPlayer';

import { LargeIconButton } from '../Buttons';

import { scrollToElement } from '../../utils';

import './CamerasSidebar.scss';

export const CamerasSidebar = ({
  activeCameras,
  cameras,
  mobile = false,
  camerasView,
  changeViewValue,
  changeCameraValue,
  setRestartStream,
  setStreamsList,
  startCameraStream,
  handleDeleteCameraStream,
  settings = false,
  camerasPreview,
  isLoading,
  subInfo,
  search,
  sortDirection,
  onCameraClick,
  getStatusColor,
  callbacks = {},
}) => {
  const [scaledImage, setScaledImage] = useState(null);

  const previewTimerRef = useRef();

  useEffect(() => {
    if (activeCameras.length === 1) {
      const activeCameraId = activeCameras[0];
      scrollToElement('cameras-sidebar-list', activeCameraId, mobile);
    }
  }, [activeCameras]);

  return (
    <div className="cameras-sidebar">
      <ul id="cameras-sidebar-list" className="cameras-sidebar__list">
        {cameras.length === 0 && isLoading && (
          <li className={cx('cameras-sidebar__list-item loader')} key="loading">
            <FontAwesomeIcon icon={faSpinner} size="lg" spin />
          </li>
        )}

        {cameras.filter((camera) => camera.name.toLowerCase().includes(search.toLowerCase())).length === 0 &&
          !isLoading && (
            <li className={cx('cameras-sidebar__list-item no-data')} key="no_cameras">
              <span>No cameras</span>
            </li>
          )}

        {cameras
          .filter((camera) => camera.name.toLowerCase().includes(search.toLowerCase()))
          .sort((a, b) => {
            if (sortDirection === 'ASC') {
              return a.name > b.name ? 1 : b.name > a.name ? -1 : 0;
            } else {
              return a.name > b.name ? -1 : b.name > a.name ? 1 : 0;
            }
          })
          .map((camera, index) => (
            <div key={camera.id || index} data-id={camera.id}>
              <li
                className={cx('cameras-sidebar__list-item', {
                  active: activeCameras.includes(camera.id),
                  rightChevron: settings,
                })}
                data-toggle="tooltip"
                data-placement="right"
                title={`${camera.name} - ${camera.ip} - ${camera.status}`}
                onClick={() => onCameraClick(camera)}
              >
                {camerasPreview && (
                  <div className="camera-preview">
                    {camerasPreview[camera.id] ? (
                      camerasPreview[camera.id].error ? (
                        <div className="load-error">
                          <NoImage />
                        </div>
                      ) : (
                        <>
                          <img
                            className={cx('camera-preview__image')}
                            src={camerasPreview[camera.id].image}
                            alt="preview"
                            onMouseEnter={() => {
                              previewTimerRef.current = setTimeout(() => {
                                setScaledImage(camera.id);
                              }, 1500);
                            }}
                            onMouseLeave={() => {
                              clearTimeout(previewTimerRef.current);
                              setScaledImage(null);
                            }}
                          />
                          {scaledImage === camera.id && (
                            <img
                              className={cx('camera-preview__image scaled')}
                              src={camerasPreview[camera.id].image}
                              alt="preview"
                              onMouseEnter={() => {
                                setScaledImage(camera.id);
                              }}
                              onMouseLeave={() => {
                                setScaledImage(null);
                              }}
                            />
                          )}
                        </>
                      )
                    ) : (
                      <FontAwesomeIcon icon={faSpinner} spin />
                    )}
                  </div>
                )}

                <div className="camera-info">
                  <span className="camera-name">{camera.name}</span>
                  {camera[subInfo] && <span className="camera-sub-info">{camera[subInfo]}</span>}
                </div>

                <div className="camera-tools">
                  {getStatusColor && getStatusColor(camera) ? (
                    <div className="camera-status" style={{ backgroundColor: getStatusColor(camera) }}></div>
                  ) : null}

                  {callbacks.onDelete && camera.removable && (
                    <LargeIconButton
                      Icon={Delete}
                      type={'gray'}
                      onClick={(event) => {
                        event.stopPropagation();
                        callbacks.onDelete(camera);
                      }}
                    />
                  )}
                </div>
              </li>
              {activeCameras.includes(camera.id) && mobile && (
                <div
                  className={cx('camera-container mobile', {
                    'modal-opened': camera && camera.modalOpened,
                  })}
                >
                  <ManagerPlayer
                    mobile={mobile}
                    key={camera?.key || index}
                    camerasView={camerasView}
                    camera={camerasView.layout.find((item) => item.id === camera.id)}
                    changeValue={changeViewValue}
                    changeCameraValue={changeCameraValue}
                    setRestartStream={setRestartStream}
                    setStreamsList={setStreamsList}
                    startCameraStream={startCameraStream}
                    onDragStart={() => {}}
                    onDeleteCameraStream={(camera) => {
                      handleDeleteCameraStream(camera, true);
                    }}
                  />
                </div>
              )}
            </div>
          ))}
      </ul>
    </div>
  );
};
