export const roundNumberTo = (number, lengthAfterPeriod = 0) => {
  const multiplier = 10 * lengthAfterPeriod;

  if (multiplier === 0) {
    return Math.round(number);
  }

  return Math.round(number * multiplier) / multiplier;
};

export const formatMinutesToSeconds = (minutes) => {
  if (!minutes) return null;

  const seconds = minutes * 60;

  return parseFloat(seconds.toFixed(2));
};

export const formatStatus = (status) => {
  return status
    .toLowerCase()
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};
