import * as types from '../actions/productionQueueAction';

const updateTimersInQueue = (data) => {
  return data.map((item) => {
    let updatedItem = item;

    if (item.taskDelayStartTime) {
      const now = Date.now();
      let newDelay;

      if (item.taskDelayEndTime) {
        const diff = item.taskDelayEndTime - item.taskDelayStartTime;
        newDelay = diff > 0 ? Math.floor(diff / 1000) : 0;
      } else {
        newDelay = Math.floor((now - item.taskDelayStartTime) / 1000);
      }

      updatedItem = { ...updatedItem, delay: newDelay };
    }

    if (item.stockoutStartTime) {
      const now = Date.now();
      const stockoutTime = now - item.stockoutStartTime;

      updatedItem = { ...updatedItem, stockoutTime };
    }

    return updatedItem;
  });
};

const initialState = {
  restaurantUuid: null,
  timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  currentQueue: 'production',
  showTimersPreview: false,
  productionQueue: {
    loading: false,
    data: [],
  },
  cateringQueue: {
    loading: false,
    data: [],
  },
  futureQueue: {
    loading: false,
    data: [],
  },
  grilledQueue: {
    loading: false,
    data: [],
  },
  timersQueue: {
    loading: false,
    data: [],
  },
  stockoutTimersQueue: {
    loading: false,
    data: [],
  },
  inventory: {
    loading: false,
    data: [],
  },
};

const productionQueueReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CHANGE_PRODUCTION_QUEUE_VALUE:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };

    case types.CHANGE_PRODUCTION_QUEUE_SEVERAL_VALUES:
      return {
        ...state,
        ...action.payload,
      };

    case types.START_QUEUE_CONNECTION:
      return {
        ...state,
        productionQueue: {
          ...state.productionQueue,
          loading: true,
        },
        cateringQueue: {
          ...state.cateringQueue,
          loading: true,
        },
        futureQueue: {
          ...state.futureQueue,
          loading: true,
        },
        grilledQueue: {
          ...state.grilledQueue,
          loading: true,
        },
        timersQueue: {
          ...state.timersQueue,
          loading: true,
        },
        stockoutTimersQueue: {
          ...state.stockoutTimersQueue,
          loading: true,
        },
        inventory: {
          ...state.inventory,
          loading: true,
        },
      };

    case types.SET_PRODUCTION_QUEUE_DATA:
      return {
        ...state,
        productionQueue: {
          data: updateTimersInQueue(action.payload),
          loading: false,
        },
      };

    case types.UPDATE_PRODUCTION_QUEUE_DATA:
      return {
        ...state,
        productionQueue: {
          data: updateTimersInQueue(state.productionQueue.data),
        },
      };

    case types.SET_CATERING_QUEUE_DATA:
      return {
        ...state,
        cateringQueue: {
          data: updateTimersInQueue(action.payload),
          loading: false,
        },
      };

    case types.UPDATE_CATERING_QUEUE_DATA:
      return {
        ...state,
        cateringQueue: {
          data: updateTimersInQueue(state.cateringQueue.data),
        },
      };

    case types.SET_FUTURE_QUEUE_DATA:
      return {
        ...state,
        futureQueue: {
          data: action.payload,
          loading: false,
        },
      };

    case types.SET_GRILLED_QUEUE_DATA:
      return {
        ...state,
        grilledQueue: {
          data: action.payload,
          loading: false,
        },
      };

    case types.SET_TIMERS_QUEUE_DATA:
      return {
        ...state,
        timersQueue: {
          data: action.payload,
          loading: false,
        },
      };

    case types.SET_STOCKOUT_TIMERS_QUEUE_DATA:
      return {
        ...state,
        stockoutTimersQueue: {
          data: action.payload,
          loading: false,
        },
      };

    case types.SET_INVENTORY_DATA:
      return {
        ...state,
        inventory: {
          data: action.payload,
          loading: false,
        },
      };

    case types.RESET_PRODUCTION_QUEUE_DATA:
      return {
        ...state,
        productionQueue: {
          ...state.productionQueue,
          data: [],
        },
        cateringQueue: {
          ...state.cateringQueue,
          data: [],
        },
        grilledQueue: {
          ...state.grilledQueue,
          data: [],
        },
        timersQueue: {
          ...state.timersQueue,
          data: [],
        },
        stockoutTimersQueue: {
          ...state.stockoutTimersQueue,
          data: [],
        },
        inventory: {
          ...state.inventory,
          data: [],
        },
      };

    case types.RESET_PRODUCTION_QUEUE_STORE:
      return {
        ...initialState,
        showTimersPreview: state.showTimersPreview,
      };

    default:
      return state;
  }
};

export default productionQueueReducer;
