import { useEffect } from 'react';

import NavItem from './NavItem';

import {
  checkSearchParameter,
  deleteSearchParameters,
  getSearchParameter,
  setSearchParameter,
} from '../../utils/urlFunctions';

import './NavBarInner.scss';

export const NavBarInner = ({
  className,
  name,
  navTabs,
  activeTab,
  RightTools,
  onChange,
  history,
  changedSearch,
  setChangedSearch,
}) => {
  useEffect(() => {
    if (name && history && checkSearchParameter(name) && navTabs.length > 0) {
      checkAddressLine();
    }
  }, [navTabs]);

  useEffect(() => {
    if (changedSearch && navTabs.length > 0) {
      const valueFromSearch = getSearchParameter(name);
      if (
        valueFromSearch &&
        navTabs.some((tab) => tab.value === valueFromSearch) &&
        valueFromSearch !== activeTab
      ) {
        onChange(name, valueFromSearch);
      }
      setChangedSearch(false);
    }
  }, [changedSearch, navTabs]);

  const checkAddressLine = () => {
    const valueFromSearch = getSearchParameter(name);
    if (valueFromSearch && navTabs.some((tab) => tab.value === valueFromSearch)) {
      if (valueFromSearch !== activeTab) {
        onChange(name, valueFromSearch);
      }
    } else {
      history.push(deleteSearchParameters([name]));
    }
  };

  const handleChange = (value) => {
    name ? onChange(name, value) : onChange(value);

    if (name && history) {
      history.push(setSearchParameter(name, value));
    }
  };

  return (
    <div className={`nav-bar-inner__container ${className}`}>
      <ul className={`nav-bar-inner`} role="tablist">
        {navTabs.map((tab, index) => (
          <NavItem
            key={index}
            label={tab.label}
            active={tab.value === activeTab}
            onClick={() => handleChange(tab.value)}
          />
        ))}
      </ul>

      {!!RightTools && <div className="nav-bar-inner__right-tools">{RightTools}</div>}
    </div>
  );
};
