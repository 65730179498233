import React, { useEffect, useState } from 'react';
import moment from 'moment';
import cx from 'classnames';

import './LocalTimeWidget.scss';

const UPDATE_DELAY = 500;

export const LocalTimeWidget = ({ className, restaurantOptions, restaurantUuid, showDefault = false }) => {
  const [currentTime, setCurrentTime] = useState(null);

  useEffect(() => {
    if (restaurantUuid !== 'select' && !!restaurantOptions) {
      const timezone = restaurantOptions.find((restaurant) => restaurant.value === restaurantUuid)?.timezone;
      const updateCurrentTime = () => {
        setCurrentTime(moment.tz(timezone).format('LTS'));
      };

      const timerId = setInterval(updateCurrentTime, UPDATE_DELAY);

      return () => clearInterval(timerId);
    } else {
      setCurrentTime(null);
    }
  }, [restaurantOptions, restaurantUuid]);

  if (!currentTime) return null;

  return (
    <div className={cx('local-time-widget', [className])}>
      <span className="local-time-widget__label">Local Time</span>
      <span className="local-time-widget__value">{currentTime}</span>
    </div>
  );
};
