import * as types from '../actions/camerasManagementAction';

const initialState = {
  view: {
    restaurantUuid: 'select',
    sortBy: 'name',
    orderDirection: 'DESC',
    search: '',
    layout: [null],
    layoutType: 'horizontal',
    selectedCameraIndex: 0,
    loading: false,
    streamsList: [],
    categories: [],
    categoriesLoading: true,
    currentCategory: 'all',
  },
  settings: {
    restaurantUuid: 'select',
    sortBy: 'name',
    orderDirection: 'DESC',
    search: '',
    cameraName: '',
    cameraUuid: null,
    profiles: null,
    codecs: null,
    resolutions: null,
    profileLimits: null,
    loading: false,
    image: null,
    imageLoading: false,
    excludeNetworkReset: false,
  },
};

const camerasManagementReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CHANGE_CAMERAS_MANAGEMENT_VIEW_VALUE: {
      return {
        ...state,
        view: {
          ...state.view,
          [action.payload.key]: action.payload.value,
        },
      };
    }
    case types.CHANGE_LAYOUT_CAMERA_VALUE: {
      return {
        ...state,
        view: {
          ...state.view,
          layout: state.view.layout.map((camera) =>
            camera?.key === action.payload.key
              ? { ...camera, [action.payload.name]: action.payload.value }
              : camera
          ),
        },
      };
    }
    case types.CHANGE_CAMERAS_MANAGEMENT_SETTINGS_VALUE: {
      return {
        ...state,
        settings: {
          ...state.settings,
          [action.payload.key]: action.payload.value,
        },
      };
    }
    case types.CHANGE_CAMERAS_MANAGEMENT_SETTINGS_SEVERAL_VALUES:
      return {
        ...state,
        settings: {
          ...state.settings,
          ...action.payload,
        },
      };
    case types.RESET_CAMERAS_MANAGEMENT_SETTINGS_INFORMATION: {
      return {
        ...state,
        settings: {
          ...state.settings,
          loading: action.payload ? false : state.loading,
          imageLoading: action.payload ? false : state.imageLoading,
          image: action.payload ? { id: state.settings.cameraUuid, image: null } : null,
          codecs: action.payload ? [] : null,
          resolutions: action.payload ? [] : null,
          profiles: action.payload ? [] : null,
        },
      };
    }
    case types.GET_CAMERA_IMAGE_SUCCESS:
    case types.GET_CAMERA_IMAGE_ERROR: {
      return {
        ...state,
        settings: {
          ...state.settings,
          image:
            action.payload.uuid === state.settings.cameraUuid
              ? { id: action.payload.uuid, image: action.payload.image }
              : state.settings.image,
          imageLoading:
            action.payload.uuid === state.settings.cameraUuid ? false : state.settings.imageLoading,
        },
      };
    }
    case types.GET_CAMERA_PROFILES:
    case types.CREATE_CAMERA_PROFILE:
    case types.EDIT_CAMERA_PROFILE:
    case types.DELETE_CAMERA_PROFILE: {
      return {
        ...state,
        settings: {
          ...state.settings,
          loading: true,
        },
      };
    }
    case types.EDIT_CAMERA_NAME_SUCCESS: {
      return {
        ...state,
        settings: {
          ...state.settings,
          ...(action.payload.uuid === state.settings.cameraUuid ? { cameraName: action.payload.name } : {}),
        },
      };
    }
    case types.GET_CAMERA_PROFILES_SUCCESS: {
      return {
        ...state,
        settings: {
          ...state.settings,
          loading: false,
          profiles:
            action.payload.uuid === state.settings.cameraUuid
              ? action.payload.profiles
              : state.settings.profiles,
        },
      };
    }
    case types.GET_CAMERA_PROFILES_ERROR:
    case types.CREATE_CAMERA_PROFILE_SUCCESS:
    case types.EDIT_CAMERA_PROFILE_SUCCESS:
    case types.DELETE_CAMERA_PROFILE_SUCCESS:
    case types.CREATE_CAMERA_PROFILE_ERROR:
    case types.EDIT_CAMERA_PROFILE_ERROR:
    case types.DELETE_CAMERA_PROFILE_ERROR: {
      return {
        ...state,
        settings: {
          ...state.settings,
          loading: false,
        },
      };
    }
    case types.GET_PROFILE_LIMITS_SUCCESS: {
      return {
        ...state,
        settings: {
          ...state.settings,
          profileLimits: action.payload,
        },
      };
    }
    case types.GET_PROFILE_LIMITS_ERROR: {
      return {
        ...state,
        settings: {
          ...state.settings,
          profileLimits: null,
        },
      };
    }
    case types.GET_CAMERA_RESOLUTIONS_SUCCESS: {
      return {
        ...state,
        settings: {
          ...state.settings,
          codecs:
            action.payload.uuid === state.settings.cameraUuid
              ? action.payload.resolutions !== null
                ? Object.keys(action.payload.resolutions)
                : []
              : state.settings.codecs,
          resolutions:
            action.payload.uuid === state.settings.cameraUuid
              ? action.payload.resolutions !== null
                ? Object.assign(
                    {},
                    ...Object.keys(action.payload.resolutions).map((data) => ({
                      [data]: action.payload.resolutions[data].map((res) => res.width + 'x' + res.height),
                    }))
                  )
                : []
              : state.settings.resolutions,
        },
      };
    }
    case types.GET_CAMERA_RESOLUTIONS_ERROR: {
      return {
        ...state,
        settings: {
          ...state.settings,
          codecs: action.payload.uuid === state.settings.cameraUuid ? [] : state.settings.codecs,
          resolutions: action.payload.uuid === state.settings.cameraUuid ? [] : state.settings.resolutions,
        },
      };
    }
    case types.SET_MANAGEMENT_RESTART_STREAM:
      return {
        ...state,
        view: {
          ...state.view,
          restart: action.payload,
        },
      };
    case types.ADD_CAMERA_TO_LAYOUT: {
      let insertIndex = 0;
      let updatedLayout = [...state.view.layout];
      const newLayoutCamera = {
        ...action.payload.camera,
        key: Date.now(),
        selectedCameraId: action.payload.camera.id,
        activeStreamId: null,
        restart: false,
        tries: 0,
        hasWebRTCError: false,
        hasStartStreamError: false,
        enableScaling: false,
        showInfo: false,
        modalOpened: false,
      };
      const camerasAmount = state.view.layout.filter((camera) => camera).length;
      switch (camerasAmount) {
        case 6: {
          updatedLayout[state.view.selectedCameraIndex] = newLayoutCamera;
          insertIndex = state.view.selectedCameraIndex;
          break;
        }
        case 5: {
          const emptyIndex = state.view.layout.findIndex((camera) => camera === null);
          updatedLayout[emptyIndex] = newLayoutCamera;
          insertIndex = emptyIndex;
          break;
        }
        case 4: {
          updatedLayout = [...state.view.layout.filter((camera) => camera), newLayoutCamera, null];
          insertIndex = 4;
          break;
        }
        case 3: {
          const emptyIndex = state.view.layout.findIndex((camera) => camera === null);
          updatedLayout[emptyIndex] = newLayoutCamera;
          insertIndex = emptyIndex;
          break;
        }
        case 2: {
          updatedLayout = [...state.view.layout.filter((camera) => camera), newLayoutCamera, null];
          insertIndex = 2;
          break;
        }
        case 1: {
          updatedLayout.push(newLayoutCamera);
          insertIndex = 1;
          break;
        }
        default: {
          updatedLayout = [newLayoutCamera];
          insertIndex = 0;
        }
      }
      return {
        ...state,
        view: {
          ...state.view,
          layout: updatedLayout,
          selectedCameraIndex: insertIndex,
        },
      };
    }
    case types.DELETE_CAMERA_FROM_LAYOUT: {
      const deleteIndex = state.view.layout.findIndex((camera) => camera?.key === action.payload.key);
      if (deleteIndex !== -1) {
        let updatedLayout = state.view.layout.map((camera, index) => (index === deleteIndex ? null : camera));
        const camerasAmount = updatedLayout.filter((camera) => camera).length;
        if (camerasAmount === 1 || camerasAmount === 2 || (updatedLayout.length > 4 && camerasAmount <= 4)) {
          updatedLayout = updatedLayout.filter((camera) => camera);
        }
        return {
          ...state,
          view: {
            ...state.view,
            layout: updatedLayout,
          },
        };
      } else {
        return state;
      }
    }
    case types.SWAP_LAYOUT_CAMERAS: {
      const firstCamera = state.view.layout[action.payload.index1];
      const secondCamera = state.view.layout[action.payload.index2];
      const updatedLayout = [...state.view.layout];
      updatedLayout[action.payload.index1] = secondCamera;
      updatedLayout[action.payload.index2] = firstCamera;
      return {
        ...state,
        view: {
          ...state.view,
          layout: updatedLayout,
        },
      };
    }
    case types.START_CAMERA_STREAM: {
      return {
        ...state,
        view: {
          ...state.view,
          layout: state.view.layout.map((camera) =>
            camera?.id === action.payload.cameraId ? { ...camera, hasStartStreamError: false } : camera
          ),
        },
      };
    }
    case types.START_CAMERA_STREAM_ERROR: {
      return {
        ...state,
        view: {
          ...state.view,
          layout: state.view.layout.map((camera) =>
            camera?.id === action.payload.cameraId ? { ...camera, hasStartStreamError: true } : camera
          ),
        },
      };
    }
    case types.START_CAMERA_STREAM_RESULT: {
      return {
        ...state,
        view: {
          ...state.view,
          layout: state.view.layout.map((camera) =>
            camera?.id === action.payload.cameraId
              ? {
                  ...camera,
                  activeStreamId: action.payload.streamId,
                  streamPin: action.payload.streamPin,
                  hasStartStreamError: false,
                }
              : camera
          ),
        },
      };
    }
    case types.GET_CAMERA_CATEGORIES: {
      return {
        ...state,
        view: {
          ...state.view,
          categories: [],
          categoriesLoading: true,
        },
      };
    }
    case types.GET_CAMERA_CATEGORIES_SUCCESS: {
      return {
        ...state,
        view: {
          ...state.view,
          categories: action.payload,
          categoriesLoading: false,
        },
      };
    }
    case types.GET_CAMERA_CATEGORIES_ERROR: {
      return {
        ...state,
        view: {
          ...state.view,
          categoriesLoading: false,
        },
      };
    }
    case types.RESET_CAMERAS_MANAGEMENT_SETTINGS_STORE: {
      return {
        ...state,
        settings: initialState.settings,
      };
    }
    case types.RESET_CAMERAS_MANAGEMENT_VIEW_STORE: {
      return {
        ...state,
        view: initialState.view,
      };
    }
    case types.RESET_CAMERAS_MANAGEMENT_STORE: {
      return {
        ...initialState,
      };
    }

    default:
      return state;
  }
};

export default camerasManagementReducer;
