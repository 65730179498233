import request from '../utils/request';

export function getCameras(restaurantUuid, params, signal) {
  return request({
    url: `${process.env.REACT_APP_CFA_PROXY}/restaurants/${restaurantUuid}/cameras`,
    method: 'get',
    data: params,
    signal,
  });
}

export function getCamera(restaurantUuid, cameraId, params) {
  return request({
    url: `${process.env.REACT_APP_CFA_PROXY}/restaurants/${restaurantUuid}/cameras/${cameraId}`,
    method: 'get',
    data: params,
  });
}

export function deleteCamera(restaurantUuid, cameraId) {
  return request({
    url: `${process.env.REACT_APP_CFA_PROXY}/restaurants/${restaurantUuid}/cameras/${cameraId}`,
    method: 'delete',
  });
}

export function getCameraProfiles(restaurantUuid, cameraUuid, params) {
  return request({
    url: `${process.env.REACT_APP_CFA_PROXY}/restaurants/${restaurantUuid}/cameras/${cameraUuid}/profiles`,
    method: 'get',
    data: params,
  });
}

export function getCameraProfileResolutions(restaurantUuid, cameraUuid) {
  return request({
    url: `${process.env.REACT_APP_CFA_PROXY}/restaurants/${restaurantUuid}/cameras/${cameraUuid}/resolutions`,
    method: 'get',
  });
}

export function getCameraProfile(restaurantUuid, cameraId, profileName) {
  return request({
    url: `${process.env.REACT_APP_CFA_PROXY}/restaurants/${restaurantUuid}/cameras/${cameraId}/profiles/${profileName}`,
    method: 'get',
  });
}

export function getCameraProfileLimits(restaurantUuid, cameraId) {
  return request({
    url: `${process.env.REACT_APP_CFA_PROXY}/restaurants/${restaurantUuid}/cameras/${cameraId}/limits`,
    method: 'get',
  });
}

export function addCameraProfile(restaurantUuid, cameraId, profileName, params) {
  return request({
    url: `${process.env.REACT_APP_CFA_PROXY}/restaurants/${restaurantUuid}/cameras/${cameraId}/profiles/${profileName}`,
    method: 'post',
    data: params,
  });
}

export function editCameraName(restaurantUuid, cameraId, params) {
  return request({
    url: `${process.env.REACT_APP_CFA_PROXY}/restaurants/${restaurantUuid}/cameras/${cameraId}`,
    method: 'patch',
    data: params,
  });
}

export function editCameraProfile(restaurantUuid, cameraId, profileName, params) {
  return request({
    url: `${process.env.REACT_APP_CFA_PROXY}/restaurants/${restaurantUuid}/cameras/${cameraId}/profiles/${profileName}`,
    method: 'put',
    data: params,
  });
}

export function deleteCameraProfile(restaurantUuid, cameraId, profileName) {
  return request({
    url: `${process.env.REACT_APP_CFA_PROXY}/restaurants/${restaurantUuid}/cameras/${cameraId}/profiles/${profileName}`,
    method: 'delete',
  });
}

export function getCameraImage(restaurantUuid, cameraId, params) {
  return request({
    url: `${process.env.REACT_APP_CFA_PROXY}/restaurants/${restaurantUuid}/cameras/${cameraId}/thumbnail`,
    method: 'get',
    data: params,
    responseType: 'blob',
  });
}

export function startCameraStream(restaurantUuid, cameraId) {
  return request({
    url: `${process.env.REACT_APP_CFA_PROXY}/restaurants/${restaurantUuid}/cameras/${cameraId}/webrtc`,
    method: 'post',
  });
}

export function stopCameraStream(restaurantUuid, cameraId, params) {
  return request({
    url: `${process.env.REACT_APP_CFA_PROXY}/restaurants/${restaurantUuid}/cameras/${cameraId}/webrtc`,
    method: 'delete',
    data: params,
  });
}

export function editCameraCredentials(restaurantUuid, cameraId, params) {
  return request({
    url: `${process.env.REACT_APP_CFA_PROXY}/restaurants/${restaurantUuid}/cameras/${cameraId}/credentials`,
    method: 'post',
    data: params,
  });
}

export function resetFactorySettings(restaurantUuid, cameraId, params) {
  return request({
    url: `${process.env.REACT_APP_CFA_PROXY}/restaurants/${restaurantUuid}/cameras/${cameraId}/reset`,
    method: 'post',
    data: params,
  });
}

export function restartCamera(restaurantUuid, cameraId, params) {
  return request({
    url: `${process.env.REACT_APP_CFA_PROXY}/restaurants/${restaurantUuid}/cameras/${cameraId}/restart`,
    method: 'post',
    data: params,
  });
}

export function scheduleRecordings(restaurantUuid, params) {
  return request({
    url: `${process.env.REACT_APP_CFA_PROXY}/restaurants/${restaurantUuid}/recordings`,
    method: 'post',
    data: params,
  });
}

export function rescheduleRecording(restaurantUuid, recordingId, params) {
  return request({
    url: `${process.env.REACT_APP_CFA_PROXY}/restaurants/${restaurantUuid}/recordings/${recordingId}`,
    method: 'put',
    data: params,
  });
}

export function stopRecording(restaurantUuid, recordingId) {
  return request({
    url: `${process.env.REACT_APP_CFA_PROXY}/restaurants/${restaurantUuid}/recordings/${recordingId}/stop`,
    method: 'post',
  });
}

export function deleteRecording(restaurantUuid, recordingId) {
  return request({
    url: `${process.env.REACT_APP_CFA_PROXY}/restaurants/${restaurantUuid}/recordings/${recordingId}`,
    method: 'delete',
  });
}

export function deleteAllCameraRecordings(restaurantUuid, cameraId) {
  return request({
    url: `${process.env.REACT_APP_CFA_PROXY}/restaurants/${restaurantUuid}/cameras/${cameraId}/recordings`,
    method: 'delete',
  });
}

export function getRecording(restaurantUuid, recordingId) {
  return request({
    url: `${process.env.REACT_APP_CFA_PROXY}/restaurants/${restaurantUuid}/recordings/${recordingId}`,
    method: 'get',
  });
}

export function getCameraRecordings(restaurantUuid, cameraId, params) {
  return request({
    url: `${process.env.REACT_APP_CFA_PROXY}/restaurants/${restaurantUuid}/cameras/${cameraId}/recordings`,
    method: 'get',
    data: params,
  });
}

export function getRecordings(restaurantUuid, params, signal) {
  return request({
    url: `${process.env.REACT_APP_CFA_PROXY}/restaurants/${restaurantUuid}/recordings`,
    method: 'get',
    data: params,
    signal,
  });
}

export function getCameraCategories(restaurantUuid, params, signal) {
  return request({
    url: `${process.env.REACT_APP_CFA_PROXY}/restaurants/${restaurantUuid}/cameras/categories`,
    method: 'get',
    data: params,
    signal,
  });
}

export function createCameraCategory(restaurantUuid, data) {
  return request({
    url: `${process.env.REACT_APP_CFA_PROXY}/restaurants/${restaurantUuid}/cameras/categories`,
    method: 'post',
    data,
  });
}

export function updateCameraCategory(restaurantUuid, categoryName, data) {
  return request({
    url: `${process.env.REACT_APP_CFA_PROXY}/restaurants/${restaurantUuid}/cameras/categories/${categoryName}`,
    method: 'put',
    data,
  });
}

export function deleteCameraCategory(restaurantUuid, categoryName) {
  return request({
    url: `${process.env.REACT_APP_CFA_PROXY}/restaurants/${restaurantUuid}/cameras/categories/${categoryName}`,
    method: 'delete',
  });
}
