import React, { useEffect, useState } from 'react';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';

import { ReactComponent as West } from '../../../assets/img/icons/common/West.svg';
import { ReactComponent as NoImage } from '../../../assets/img/icons/common/NoImage.svg';

import { FormQuantitySelect, ModalPattern } from '../../../components';

import './ModalSelectCameras.scss';

const camerasAmountOptions = Array.from({ length: 5 }, (_, index) => ({
  label: (index + 1).toString(),
  value: index + 1,
}));

export const ModalSelectCameras = ({
  title,
  cameras,
  camerasPreview,
  camerasLoading,
  singleAmount = false,
  onConfirm,
  onCancel,
}) => {
  const [camerasAmount, setCamerasAmount] = useState({});

  useEffect(() => {
    setCamerasAmount(
      cameras.reduce((acc, camera) => {
        acc[camera.id] = 0;
        return acc;
      }, {})
    );
  }, [cameras]);

  const handleSelectCameraAmount = (cameraId, amount) => {
    setCamerasAmount((prev) => ({
      ...prev,
      [cameraId]: amount,
    }));
  };

  const handleConfirm = () => {
    const selectedCameras = Object.entries(camerasAmount).reduce((acc, [cameraId, amount]) => {
      if (amount > 0) {
        const selectedCamera = cameras.find((camera) => camera.id === cameraId);
        if (selectedCamera) {
          return [...acc, ...Array(amount).fill(selectedCamera)];
        }
      }
      return acc;
    }, []);

    onConfirm(selectedCameras);
  };

  return (
    <ModalPattern
      size={'lg'}
      title={title}
      onConfirm={handleConfirm}
      onCancel={onCancel}
      onClose={onCancel}
      modalBody={
        <div className="gap-12 separate-modal-select-cameras">
          <div className="separate-modal-select-cameras__header">
            <h4 className="separate-modal-select-cameras__header__title">Select Cameras</h4>
            <button className="separate-modal-select-cameras__header__back-button" onClick={onCancel}>
              <West />
            </button>
          </div>

          <div className="cameras-container">
            {!camerasLoading && cameras.length > 0 ? (
              cameras.map((camera) => (
                <div
                  key={camera.id}
                  className={cx('camera-element', {
                    active: camerasAmount[camera.id] && camerasAmount[camera.id] > 0,
                  })}
                  onClick={() => {
                    if (camerasAmount[camera.id] === 0) {
                      setCamerasAmount((prev) => ({ ...prev, [camera.id]: 1 }));
                    } else {
                      setCamerasAmount((prev) => ({ ...prev, [camera.id]: 0 }));
                    }
                  }}
                >
                  {!singleAmount && camerasAmount[camera.id] && camerasAmount[camera.id] > 0 ? (
                    <FormQuantitySelect
                      className="camera-amount-select"
                      options={camerasAmountOptions}
                      value={camerasAmount[camera.id]}
                      onChange={(value) => handleSelectCameraAmount(camera.id, value)}
                    />
                  ) : null}
                  <div
                    className={cx('camera-indicator', {
                      online: camera.status.toLowerCase() === 'online',
                    })}
                  />
                  <div className="camera-title">{camera.name}</div>
                  {camerasPreview[camera.id]?.error ? (
                    <div className="image-content">
                      <NoImage />
                    </div>
                  ) : camerasPreview[camera.id]?.loading ? (
                    <div className="image-content md">
                      <FontAwesomeIcon icon={faSpinner} spin size="md" />
                    </div>
                  ) : (
                    <img
                      className="camera-preview"
                      src={camerasPreview[camera.id]?.image}
                      alt={camera.name}
                    />
                  )}
                </div>
              ))
            ) : camerasLoading ? (
              <div className="cameras-container__loader">
                <FontAwesomeIcon icon={faSpinner} spin size={'lg'} />
              </div>
            ) : (
              <div className="cameras-container__info">
                <div>No suitable cameras</div>
              </div>
            )}
          </div>
        </div>
      }
      confirmText="Add"
      confirmDisabled={cameras.length === 0 || !Object.values(camerasAmount).some((amount) => amount > 0)}
    />
  );
};
