import { ReactComponent as AuthLog } from '../assets/img/icons/userSettings/authLog.svg';
import { ReactComponent as Security } from '../assets/img/icons/userSettings/security.svg';
import { ReactComponent as Sessions } from '../assets/img/icons/userSettings/sessions.svg';

import { ReactComponent as AuditLog } from '../assets/img/icons/sidebar/AuditLog.svg';
import { ReactComponent as Cameras } from '../assets/img/icons/sidebar/Cameras.svg';
import { ReactComponent as Dashboard } from '../assets/img/icons/sidebar/Dashboard.svg';
import { ReactComponent as Devices } from '../assets/img/icons/sidebar/Devices.svg';
import { ReactComponent as Events } from '../assets/img/icons/sidebar/Events.svg';
import { ReactComponent as KitchenPlayer } from '../assets/img/icons/sidebar/KitchenPlayer.svg';
import { ReactComponent as Monitoring } from '../assets/img/icons/sidebar/Monitoring.svg';
import { ReactComponent as Performance } from '../assets/img/icons/sidebar/Performance.svg';
import { ReactComponent as PlugPlay } from '../assets/img/icons/sidebar/PlugPlay.svg';
import { ReactComponent as Queue } from '../assets/img/icons/sidebar/Queue.svg';
import { ReactComponent as Reports } from '../assets/img/icons/sidebar/Reports.svg';
import { ReactComponent as Restaurants } from '../assets/img/icons/sidebar/Restaurants.svg';
import { ReactComponent as Settings } from '../assets/img/icons/sidebar/Settings.svg';
import { ReactComponent as StockOut } from '../assets/img/icons/sidebar/StockOut.svg';
import { ReactComponent as TimersCycleHistory } from '../assets/img/icons/sidebar/TimersCycleHistory.svg';
import { ReactComponent as Unusual } from '../assets/img/icons/sidebar/Unusual.svg';
import { ReactComponent as Users } from '../assets/img/icons/sidebar/Users.svg';

import * as R from './routes.constants';

export const pages = [
  {
    link: R.CHICKEN_DASHBOARD,
    i: 'fas fa-chart-pie',
    Icon: Dashboard,
    span: 'Dashboard',
    disabled: false,
    tooltip: [
      {
        link: R.CHICKEN_DASHBOARD,
        i: '',
        span: 'Chicken',
        disabled: false,
      },
      //TODO: return when is ready
      // {
      //   link: R.FRAHA_DASHBOARD,
      //   i: '',
      //   span: 'Potato',
      //   disabled: false,
      // },
    ],
  },
  {
    link: R.PRODUCTION_QUEUE,
    i: 'fas fa-list-alt',
    Icon: Queue,
    span: 'PCS',
    disabled: false,
    tooltip: [
      {
        link: R.PRODUCTION_QUEUE,
        span: 'Monitor',
        disabled: false,
      },
      {
        link: R.CATERING_INVENTORY,
        span: 'Catering Inventory',
        disabled: false,
      },
      {
        link: R.RECOMMENDATIONS,
        span: 'Recommendations',
        disabled: false,
      },
      {
        link: R.FRYER_MANAGER,
        span: 'Fryer Manager',
        disabled: false,
      },
      {
        link: R.ADJUSTMENT,
        span: 'Adjustment',
        disabled: false,
      },
    ],
  },
  {
    link: R.CHICKEN_EVENTS,
    i: 'fa fa-table',
    Icon: Events,
    span: 'Events',
    disabled: false,
    tooltip: [
      {
        link: R.CHICKEN_EVENTS,
        i: '',
        span: 'Chicken',
        disabled: false,
      },
      {
        link: R.POTATO_EVENTS,
        i: '',
        span: 'Potato',
        disabled: false,
      },
      {
        link: R.ANALYTIC_EVENTS,
        i: '',
        span: 'Analytics',
        disabled: false,
      },
    ],
  },
  {
    link: R.BREADING_EVENTS,
    i: 'fa fa-clock',
    Icon: TimersCycleHistory,
    span: 'Timers Cycle History',
    disabled: false,
    tooltip: [
      {
        link: R.BREADING_EVENTS,
        i: '',
        span: 'Breading',
        disabled: false,
      },
      {
        link: R.FRYER_EVENTS,
        i: '',
        span: 'Fryer',
        disabled: false,
      },
      {
        link: R.CONVEYOR_EVENTS,
        i: '',
        span: 'Conveyor',
        disabled: false,
      },
      {
        link: R.HOT_HOLDING,
        i: '',
        span: 'Hot Holding',
        disabled: false,
      },
      {
        link: R.CATERING_EVENTS,
        i: '',
        span: 'Catering',
        disabled: false,
      },
      // TODO: temporary hidden pages (search for 'hidden pages')
      // {
      //   link: R.PRQ,
      //   i: '',
      //   span: 'PRQ',
      //   disabled: false,
      // },
      // {
      //   link: R.DUMP_PAN_EVENTS,
      //   i: '',
      //   span: 'Dump Pan',
      //   disabled: false,
      // },
      // {
      //   link: R.FRY_CHUTES_EVENTS,
      //   i: '',
      //   span: 'Fry Chutes',
      //   disabled: false,
      // },
      {
        link: R.TIMERS_ANALYTICS,
        i: '',
        span: 'Analytics',
        disabled: false,
      },
    ],
  },
  {
    link: R.STOCK_OUT_REPORTS,
    i: 'fas fa-cart-arrow-down',
    Icon: StockOut,
    span: 'Stock Out',
    disabled: false,
    tooltip: [
      {
        link: R.STOCK_OUT_REPORTS,
        i: '',
        span: 'Chicken',
        disabled: false,
      },
    ],
  },
  {
    link: R.CHICKEN_PERFORMANCE,
    i: 'fas fa-percent',
    Icon: Performance,
    span: 'Performance',
    disabled: false,
    tooltip: [
      {
        link: R.CHICKEN_PERFORMANCE,
        i: '',
        span: 'Chicken',
        disabled: false,
      },
    ],
  },
  {
    link: R.UNUSUAL_EVENT,
    i: 'far fa-surprise',
    Icon: Unusual,
    span: 'Unusual',
    disabled: false,
    tooltip: [
      {
        link: R.UNUSUAL_EVENT,
        i: '',
        span: 'Unusual Events',
        disabled: false,
      },
      {
        link: R.UNUSUAL_DP_REMAINING,
        i: '',
        span: 'DP Remaining Potato',
        disabled: false,
      },
      {
        link: R.UNUSUAL_WRONG_MARKER,
        i: '',
        span: 'Wrong Marker Detected',
        disabled: false,
      },
      {
        link: R.UNUSUAL_IDENTICAL,
        i: '',
        span: 'Identical Markers',
        disabled: false,
      },
      {
        link: R.UNUSUAL_RECORDINGS,
        i: '',
        span: 'Unusual Recordings',
        disabled: false,
      },
    ],
  },
  {
    link: R.KITCHEN_PLAYER,
    i: 'fas fa-video',
    Icon: KitchenPlayer,
    span: 'Kitchen Player',
    disabled: false,
    tooltip: [
      {
        link: R.KITCHEN_PLAYER,
        i: 'fas fa-eye',
        span: 'Player',
        disabled: false,
      },
      {
        link: R.KITCHEN_PLAYER_RECORDINGS,
        i: 'fas fa-film',
        span: 'Recordings',
        disabled: false,
      },
    ],
  },
  {
    link: R.PLUG_TAB,
    i: 'fas fa-play',
    Icon: PlugPlay,
    span: 'Plug & Play',
    disabled: false,
    tooltip: [
      {
        link: R.PLUG_TAB,
        i: '',
        span: 'PnP',
        disabled: false,
      },
      {
        link: R.ARUCO_VALIDATION,
        i: '',
        span: 'Aruco Validation',
        disabled: false,
      },
    ],
  },
  {
    link: R.CAMERAS_MANAGEMENT_VIEW,
    i: 'fas fa-camera',
    Icon: Cameras,
    span: 'Cameras',
    disabled: false,
    tooltip: [
      {
        link: R.CAMERAS_MANAGEMENT_VIEW,
        i: 'fas fa-eye',
        span: 'View',
        disabled: false,
      },
      {
        link: R.CAMERAS_MANAGEMENT_SETTINGS,
        i: 'fas fa-cog',
        span: 'Settings',
        disabled: false,
      },
      {
        link: R.CAMERAS_MANAGEMENT_RECORDINGS,
        i: 'fas fa-film',
        span: 'Recordings',
        disabled: false,
      },
    ],
  },
  {
    link: R.DEVICES_PC,
    i: 'fas fa-tablet-alt',
    Icon: Devices,
    span: 'Devices',
    disabled: false,
    tooltip: [
      {
        link: R.DEVICES_PC,
        i: '',
        span: 'PC',
        disabled: false,
      },
      {
        link: R.DEVICES_TABLETS,
        i: '',
        span: 'Tablets',
        disabled: false,
      },
    ],
  },
  {
    link: R.RESTAURANTS,
    i: 'fas fa-store',
    Icon: Restaurants,
    span: 'Restaurants',
    disabled: false,
  },
  {
    link: R.SERVER_MANAGEMENT,
    i: 'fas fa-chart-line',
    Icon: Monitoring,
    span: 'Monitoring',
    disabled: false,
    tooltip: [
      {
        link: R.SERVER_MANAGEMENT,
        i: '',
        span: 'Server Management',
        disabled: false,
      },
      {
        link: R.MONITORING_GRAPHICS,
        i: '',
        span: 'Graphics',
        disabled: false,
      },
      {
        link: R.MONITORING_ALERTS,
        i: '',
        span: 'Alerts',
        disabled: false,
      },
    ],
  },
  {
    link: R.REPORTS,
    i: 'fas fa-file-invoice',
    Icon: Reports,
    span: 'Reports',
    disabled: false,
  },
  {
    link: R.SETTINGS_CHICKEN_MATERIALS,
    i: 'fa fa-cog',
    Icon: Settings,
    span: 'Settings',
    disabled: false,
    tooltip: [
      {
        link: R.SETTINGS_CHICKEN_MATERIALS,
        span: 'Chicken',
        disabled: false,
      },
      {
        link: R.SETTINGS_POTATO_MATERIALS,
        span: 'Potato',
        disabled: false,
      },
    ],
  },
  {
    link: R.USERS,
    i: 'fas fa-users',
    Icon: Users,
    span: 'Users',
    disabled: false,
  },
  {
    link: R.AUDIT_LOG,
    i: 'fas fa-list-alt',
    Icon: AuditLog,
    span: 'Audit Log',
    disabled: false,
  },
];

export const accessNames = [
  { module: 'ANALYTIC', pages: [R.CHICKEN_DASHBOARD, R.FRAHA_DASHBOARD] },
  { module: 'EVENT', pages: [R.POTATO_EVENTS, R.ANALYTIC_EVENTS] },
  { module: 'CHICKEN_EVENTS', pages: [R.CHICKEN_EVENTS, R.ANALYTIC_EVENTS] },
  { module: 'TIMER_CYCLE_HISTORY', pages: [R.HOT_HOLDING, R.TIMERS_ANALYTICS] },
  { module: 'PRQ_TIMER_CYCLE_HISTORY', pages: [R.PRQ, R.TIMERS_ANALYTICS] },
  { module: 'BREADING_TIMER_CYCLE_HISTORY', pages: [R.BREADING_EVENTS, R.TIMERS_ANALYTICS] },
  { module: 'CONVEYOR_TIMER_CYCLE_HISTORY', pages: [R.CONVEYOR_EVENTS, R.TIMERS_ANALYTICS] },
  { module: 'CATERING_TIMER_CYCLE_HISTORY', pages: [R.CATERING_EVENTS, R.TIMERS_ANALYTICS] },
  { module: 'POTATO_PERFORMANCE', pages: [R.FRAHA_DASHBOARD, R.CHICKEN_DASHBOARD] },
  { module: 'CHICKEN_PERFORMANCE', pages: [R.CHICKEN_PERFORMANCE] },
  { module: 'DUMP_PAN_INFO', pages: [R.DUMP_PAN_EVENTS, R.TIMERS_ANALYTICS] },
  { module: 'FRY_CHUTES_INFO', pages: [R.FRY_CHUTES_EVENTS, R.TIMERS_ANALYTICS] },
  { module: 'BASKET_EVENTS', pages: R.BASKET_EVENTS },
  { module: 'ENTRY_CHUTES', pages: R.ENTRY_CHUTES },
  { module: 'FRYER_TIMER_CYCLE_HISTORY', pages: [R.FRYER_EVENTS, R.TIMERS_ANALYTICS] },
  { module: 'AUDIT', pages: R.AUDIT_LOG },
  {
    module: 'CAMERAS',
    pages: [R.CAMERAS_MANAGEMENT_VIEW, R.CAMERAS_MANAGEMENT_SETTINGS, R.CAMERAS_MANAGEMENT_RECORDINGS],
  },
  { module: 'CAMERAS_VIEW', pages: R.CAMERAS_MANAGEMENT_VIEW },
  { module: 'CAMERAS_RECORDINGS', pages: R.CAMERAS_MANAGEMENT_RECORDINGS },
  { module: 'DEVICE', pages: [R.DEVICES_PC, R.DEVICES_TABLETS] },
  { module: 'GLOBAL_SETTINGS', pages: R.SETTINGS_PACKAGES },
  { module: 'RESTAURANT', pages: R.RESTAURANTS },
  {
    module: 'UNUSUAL_EVENT',
    pages: [
      R.UNUSUAL_EVENT,
      R.UNUSUAL_DP_REMAINING,
      R.UNUSUAL_IDENTICAL,
      R.UNUSUAL_WRONG_MARKER,
      R.UNUSUAL_RECORDINGS,
    ],
  },
  { module: 'DICTIONARIES_EVENT_NAME', pages: '' },
  { module: 'DICTIONARIES_CHICKEN_EVENT_NAME', pages: '' },
  { module: 'KITCHEN_PLAYER', pages: [R.KITCHEN_PLAYER, R.KITCHEN_PLAYER_RECORDINGS] },
  { module: 'PLUG_AND_PLAY', pages: R.PLUG_TAB },
  { module: 'MATERIALS', pages: [R.SETTINGS_MATERIALS] },
  { module: 'CONTAINERS', pages: [R.SETTINGS_CONTAINERS] },
  { module: 'CHICKEN_SETTINGS', pages: [R.SETTINGS_CHICKEN_MATERIALS, R.SETTINGS_CHICKEN_CONTAINERS] },
  { module: 'POTATO_SETTINGS', pages: [R.SETTINGS_POTATO_MATERIALS, R.SETTINGS_POTATO_PACKAGES] },
  { module: 'STOCK_OUT', pages: [R.STOCK_OUT_REPORTS] },
  {
    module: 'PRODUCTION',
    pages: [R.PRODUCTION_QUEUE, R.FRYER_MANAGER, R.CATERING_INVENTORY, R.RECOMMENDATIONS],
  },
  { module: 'CATERING_ORDERS', pages: [R.CATERING_INVENTORY] },
  { module: 'FRYERS', pages: [R.FRYER_MANAGER] },
  { module: 'REPORTS', pages: [R.REPORTS] },
  { module: 'MATERIAL_SCALES', pages: [R.ADJUSTMENT] },
];

export const userSidebar = [
  {
    link: R.AUTHENTIFICATION_LOG,
    i: <AuthLog />,
    span: 'Authentication Log',
    disabled: false,
  },
  {
    link: R.ACTIVE_SESSIONS,
    i: <Sessions />,
    span: 'Active Sessions',
    disabled: false,
  },
  {
    link: R.USER_SECURITY,
    i: <Security />,
    span: 'Security',
    disabled: false,
  },
];
