import React from 'react';

import { formatStatus } from '../../../utils/formatting';

import './StatusCell.scss';

export const StatusCell = ({ status, options, customFormatting = false }) => {
  return (
    <div className="custom-cell__status" style={{ color: options[status.toLowerCase()] || '#1A1A1A' }}>
      {customFormatting ? formatStatus(status) : status}
    </div>
  );
};
